import React from "react"

import Layout from "../components/common/layout/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="hero-section">
        <div className="content-layer">
          <h1>Oops, the page you are looking for doesn't exist</h1>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
